import React from 'react';
import styled from 'styled-components';
import { FaBars, FaUserCircle, } from 'react-icons/fa';
import { FiLogOut, FiSettings } from 'react-icons/fi';



export const headerLogo = styled.img`
    padding:20px;
    width: 100pxx;
    height: 100px;
    margin: 0px;
    width:100%;
`

export const toolBar = styled.div`
height: 30px;
width: 100%;
justify-content: space-between;
align-itens:center;
`


export const containerLogoToolBar = styled.div`
display: grid;
grid-auto-flow: column;
width:100%;
justify-content: center; 
`


export const headerLogoToolBar = styled.img`
    width: 70px;
    margin-top: -20px;
    align-itens: center;
`




export const headerConfirToolBar = styled.div`
display: grid;
grid-auto-flow: column;
width:100%;
justify-content: right; 
`


export const FaIconBar = styled(FaBars)`
font-size:40px;
padding:10px;
color:  ${({ theme }) => theme.colors.white}!important;

`;

export const FaIconUser = styled(FaUserCircle)`
font-size:24px;
padding:3px;
color:  ${({ theme }) => theme.colors.white}!important;
`;

export const FaIconConf = styled(FiSettings)`
font-size:24px;
padding:3px;
color:  ${({ theme }) => theme.colors.white}!important;

`;

export const FaIconSair = styled(FiLogOut)`
font-size:24px;
letter-spacing: -1px;

padding:3px;
color: ${({ theme }) => theme.colors.white};
`;


export const menu = styled.div`
flex:1;
flex-direction: column;

justify-content: space-between;
height:100px;
`


export const box = styled.div`
flex:1;
flex-direction: column;
justify-content: space-between;
`