import React from 'react';
import styled from 'styled-components';
import { FaFolderOpen, FaUser, } from 'react-icons/fa';
import { FiHelpCircle } from 'react-icons/fi';



export const body = styled.div`
min-height: calc(100vh - 300px);
top:0;
padding:30px;
margin-top:100px;
border-radius:20px;
transition: all 0.3s ease-out;
-webkit-box-flex: 0;
-ms-flex: 0 0 100%;
flex: 0 0 100%;
max-width: 100%;
`

export const FaUse = styled(FaUser)`
font-size:25px;
color: ${({ theme }) => theme.colors.white_light};
padding:3px;
`;

export const FaHelper = styled(FiHelpCircle)`
font-size:20px;
color: ${({ theme }) => theme.colors.white_light};
`;






export const nameuser = styled.div`
top:0;
color: ${({ theme }) => theme.colors.white_light} ;
margin-top:-10px;
transition: all 0.3s ease-out;
max-width: 100%;
margin-bottom:20px ;
font-size: 16px;
`


export const contBank = styled.div`
margin-top:20px;
`

export const section = styled.div`
border-top: 0.5rem ${({ theme }) => theme.colors.primary} solid;
min-height: calc(100vh - 400px);
top:0;
padding:30px;
margin-left:10px;
margin-right:10px;
box-shadow: 7px 7px 13px 20px rgba(50, 50, 50, 0.22);
border-radius:20px;
transition: all 0.3s ease-out;
background-color: #FFF;
-webkit-box-flex: 0;
-ms-flex: 0 0 100%;
flex: 0 0 100%;
max-width: 100%;
border-bottom: 0.5rem ${({ theme }) => theme.colors.secondary} solid;


`

export const card = styled.div`
border-radius: 5px;
margin-top:20px;
box-shadow: 20px 20px 13px 0px rgba(77, 50, 50, 0.50);
padding: 20px;
width: 100%;
transition: all 0.3s ease-out;
background-color: ${({ theme }) => theme.colors.primary}

`

export const cardTable = styled.div`
border-radius: 5px;
box-shadow: 7px 7px 13px 0px rgba(50, 50, 50, 0.22);
padding: 20px;
width: 100%;
transition: all 0.3s ease-out;
overflow-x: scroll;
overflow-x: auto;
border-top: 0.5rem #b574ee solid;


`

export const MainDiv = styled.div`
color: pink;
background-color: red;
width:100%;
`


export const textTitleScreen = styled.text`
font-weight: bolder ;
color: ${({ theme }) => theme.colors.white_light};
font-weight: 400;
font-size: 1.75rem;
padding-bottom: 10px;
`


export const textTitleBank = styled.text`
font-weight: bolder ;
color: ${({ theme }) => theme.colors.white_light};
font-weight: 600;
font-size: 1.75rem;

`


export const textTitleTble = styled.text`
font-weight: bolder ;
color: #4685c4;
font-weight: 500;
font-size: 1.40rem;
padding-bottom: 10px;
`

export const textCardTitle = styled.text`
color: ${({ theme }) => theme.colors.white};
font-family: Arial, Helvetica, sans-serif;
font-size: 14px;
letter-spacing: 0.4px;
word-spacing: 0px;
font-weight: 700;
text-decoration: none;
font-style: normal;
font-variant: small-caps;
text-transform: none;
`


export const textCard = styled.text`
font-size: 14px;
color: ${({ theme }) => theme.colors.white_light};

`


export const headerLogo = styled.img`
width: 100px;
height: 100px;
margin: 0px;
width:100%;
`


export const cardLogin = styled.div`
margin-top:40px;
`





