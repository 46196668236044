import React, { useState } from 'react';
import * as S from './style.js'
import { useAuth } from "../../hooks/Authetication";


export default function Cabecalho( { title = null }) {
    const { user } = useAuth();
    return (
      <S.sectionFooter>
      <S.nameuser> <S.FaUse /> Bem vindo -  {user.name}</S.nameuser>
      <S.textTitleScreen>{title}</S.textTitleScreen>
      </S.sectionFooter> 

    )
}
