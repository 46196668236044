import React, { Component, useState, useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom';
import *  as S from './style';
import "./index.css";
import Imglogo from '../../img/logo.png'
import bg from '../../img/bg01.jpg'
import { useAuth } from '../../hooks/Authetication'
import { useAlerta } from "../../hooks/alerta";



export default function Login() {

    const { signIn } = useAuth();

    let navigate = useNavigate();
   
   const [labelLogin, setLabelLogin]= useState("");
   const [labelPsw, setLabelPsw]= useState("");

   
   
   
   
    async function sendLogin() {
        const call =  await signIn({
            cpf: labelLogin,
            password: labelPsw,
            tokenExpo: 'devicePC',
          });
          
    }

    const getInputLogin = (event)=>{
       setLabelLogin(event.target.value);
    };

    return (
        <>

            <div className="outer">
                <div className="inner2">
                    <S.headerLogo src={Imglogo}>

                    </S.headerLogo>

                    <form>

                        <S.cardLogin>
                            <div className="form-group">
                                <label className="labelForm">CPF / CNPJ</label>
                                <input type="number" className="form-control" name="login" onChange={text => setLabelLogin(text.target.value)}   placeholder="Digite o CPF ou CNPJ" />
                            </div>

                            <div className="form-group">
                                <label className="labelForm">Senha</label>
                                <input type="password" className="form-control" name="psw" onChange={text => setLabelPsw(text.target.value)}  placeholder="Digite a sua senha" />
                            </div>

                            <div className="form-group">
                                <div className="custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" id="customCheck1" />
                                    <label className="custom-control-label labelForm" htmlFor="customCheck1">  Lembrar-me </label>
                                </div>
                            </div>

                            <button type="button" className="btn btn-success btn-lg btn-block btnSend" onClick={() => sendLogin()}>Entrar</button>
                            <p className="forgot-password text-right">
                            </p>
                        </S.cardLogin>
                    </form>
                </div>
            </div>
        </>

    );
}


