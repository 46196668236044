import React, { Component, useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import *  as S from './style';
import Imglogo from '../../img/logo.png'
import bg from '../../img/bg01.jpg'
import Menu from "../../components/menu";
import Main from "../../components/main";
import Footer from "../../components/footer";
import Divider from "../../components/divider";
import ilikeApi from "../../utils/api/ilikepay";
import { useAuth } from "../../hooks/Authetication";
import { useAlerta } from "../../hooks/alerta";
import ReactFC from "react-fusioncharts";

import FusionCharts from "fusioncharts";
import Column2D from "fusioncharts/fusioncharts.charts";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";


import { Container, Row, Col, Table } from 'react-bootstrap';
import Cabecalho from "../../components/cabecalho";


export default function Dashboard() {

    const [dataApi, setDataApi] = useState([]);
    const [dataSaldo, setDataSaldo] = useState([]);
    const [chartData, setChartData1] = useState([
        {
            "label": "Títulos", 
            "value": 1
        } 

    ]);
    const [chartData2, setChartData2] = useState([
        {
            "label": "Títulos", 
            "value": 0
        }
    ]);





    const { user } = useAuth();
    const { showAlert, hideAlert, showAlertTime } = useAlerta(); 
    const [help, setHelp] = useState([]);
    ReactFC.fcRoot(FusionCharts, Column2D, FusionTheme);


  
      
      // STEP 3 - Creating the JSON object to store the chart configurations
      const chartConfigs = {
        type: "pie2d", // The chart type
        width: "100%", // Width of the chart
        height: "400", // Height of the chart
        dataFormat: "json", // Data type
        dataSource: {
          // Chart Configuration
          chart: {
            //Set the chart caption
            caption: "Status de contas a pagar",
            //Set the chart subcaption
            subCaption: "Gráfico com base nos últimos 7 dias.",
            theme: "fusion"
          },
          // Chart Data
          data: chartData
        }
      };

    const chartConfigsBar = {
        type: "column2d", // The chart type
        width: "100%", // Width of the chart
        height: "400", // Height of the chart
        dataFormat: "json", // Data type
        dataSource: {
          // Chart Configuration
          chart: {
            //Set the chart caption
            caption: "Valores Liquidados",
            //Set the chart subcaption
            numberPrefix: "R$",
            animation: true, 
            tickValueDecimals: "2",
            forceTickValueDecimals: "1",

            subCaption: "Gráfico com base nos últimos 7 dias.",
            theme: "fusion"
          },
          // Chart Data
          data: chartData2
        }
      };


      

    function helpEmpenho(){

        if (help == true) {
            hideAlert(false);
            setHelp(false);
        } else {
            setHelp(true);
            showAlert({ title: "Ajuda", message: "Saldo reservado para pagamentos de títulos em aberto.", type: "warning" })
        }
    }


    function helpLiquidado(){
        if (help == true) {
            hideAlert(false);
            setHelp(false);
        } else {
            setHelp(true);
            showAlert({ title: "Ajuda", message: "Total de pagamentos processando no dia.", type: "warning" })
        }
    }

    async function chamaapi() {
        var data = JSON.stringify({
            "uuid": user.uuid,
            "periodo_inicio": null,
            "periodo_fim": null,
            "status": null
        });
        var axiosConfig = {
            headers: {
                'Content-Type': 'application/json',
            }
        };
        const resposta = await ilikeApi.post("/pag-remessa/lista-lotes", data, axiosConfig);
        if (resposta.data.error === 0) {
            setDataApi(resposta.data.res);
        }
    }


    async function viewGraph01() {
       

        var data = JSON.stringify({
            "uuid": user.uuid,
            "metodo": 'GF1',
        });
        var axiosConfig = {
            headers: {
                'Content-Type': 'application/json',
            }
        };
        const resposta = await ilikeApi.post("/dashboard/view", data, axiosConfig);
        if (resposta.data.error === 0) {
            setChartData1([]);
            setChartData1(resposta.data.res);
        }
    }

    async function viewGraph02() {
       

        var data = JSON.stringify({
            "uuid": user.uuid,
            "metodo": 'GF2',
        });
        var axiosConfig = {
            headers: {
                'Content-Type': 'application/json',
            }
        };
        
        const resposta = await ilikeApi.post("/dashboard/view", data, axiosConfig);
        if (resposta.data.error === 0) {
            setChartData2([]);
            setChartData2(resposta.data.res);
        }
    }



    async function consultaSaldo() {  
      var axiosConfig = {
            headers: {
                'Content-Type': 'application/json',            
            }
        };
        ilikeApi.defaults.headers.common['Authorization'] = `Bearer ${String(user.access_token)}`;
        const resposta = await ilikeApi.post(`/banking/consulta-saldo-empenho/${user.uuid}`, axiosConfig);
        if (resposta.data.error === 0) {
            setDataSaldo(resposta.data.res);
        }

    }

    useEffect(() => {

   
        consultaSaldo();
        chamaapi();
        viewGraph01();
        viewGraph02();



    }, []);


    return (
        <>
            <Menu />
            <S.body>
            <Cabecalho title={'Dashboard'} />
                <Divider />
                <S.section>


                    <Row>
                        <Col md={4}>
                            <S.card>

                                <S.textCardTitle>Saldo:</S.textCardTitle>
                                <br></br>
                                <S.textCard>R$: {dataSaldo.Saldo}</S.textCard>
                            </S.card>



                        </Col>

                        <Col md={4}>
                            <S.card>

                                <S.textCardTitle>Empenhado: <S.FaHelper onClick={() => helpEmpenho()}/> </S.textCardTitle>
                                <br></br>
                                <S.textCard>R$: {dataSaldo.Empenhado}</S.textCard>
                            </S.card>


                        </Col>
                        <Col md={4}>
                            <S.card>


                                <S.textCardTitle>Liquidado:  <S.FaHelper onClick={() => helpLiquidado()}/> </S.textCardTitle>
                                <br></br>
                                <S.textCard>R$: {dataSaldo.LiquidadoHoje}</S.textCard>
                            </S.card>


                        </Col>


                    </Row>

                    <br></br><br></br>

                    <Row>
                      
                        <Col md={6}>

                        <ReactFC {...chartConfigs} />
                        </Col>
                        <Col md={6}>
                        <ReactFC {...chartConfigsBar} />

                            {/* <S.cardTable>
                                <S.textTitleTble>Últimos lancamentos</S.textTitleTble>

                                <Table striped size="md" responsive>
                                    <thead>
                                        <tr>
                                            <th>Lote</th>
                                            <th>Qtd de Títulos</th>
                                            <th>Data</th>
                                            <th>Hora</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {dataApi.map((item) => (
                                            <tr key={item.SJ1_NUM}>
                                                <td>{item.SJ1_NUM}</td>
                                                <td>{item.Quantidade}</td>
                                                <td>{item.SJ1_DT}</td>
                                                <td>{item.SJ1_HR}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </S.cardTable> */}
                        </Col>
                    </Row>
                </S.section>
                <br></br>
                <br></br>

                <S.textTitleBank>Opções de Abastecimento</S.textTitleBank>
                <S.section>

                <S.contBank>
              
                <Divider />


                <Row>
                <Col md={4}>
                            <S.card>

                                <S.textCardTitle>Banco Itaú - 341</S.textCardTitle>
                                <br></br> 
                                <S.textCard><b> Instituição:</b> I Like Technology LTDA</S.textCard>
                                <br></br> 
                                <S.textCard><b>CNPJ:</b> 45.123.168/0001-22</S.textCard>
                                <br></br> 
                                <S.textCard><b>Agência:</b> 8725</S.textCard>
                                <br></br> 
                                <S.textCard><b>Conta:</b> 99620-4</S.textCard>
                                <br></br> 
                            </S.card>
                </Col>
                <Col md={4}>
                            <S.card>

                                <S.textCardTitle>Banco Bradesco - 237</S.textCardTitle>
                                <br></br> 
                                <S.textCard><b> Instituição:</b> I Like Technology LTDA</S.textCard>
                                <br></br> 
                                <S.textCard><b>CNPJ:</b> 45.123.168/0001-22</S.textCard>
                                <br></br> 
                                <S.textCard><b>Agência:</b> 0091</S.textCard>
                                <br></br> 
                                <S.textCard><b>Conta:</b> 35968-8</S.textCard>
                                <br></br> 
                            </S.card>
                </Col>

                <Col md={4}>
                            <S.card>

                                <S.textCardTitle>Banco Santader - 033</S.textCardTitle>
                                <br></br> 
                                <S.textCard><b> Instituição:</b> I Like Technology LTDA</S.textCard>
                                <br></br> 
                                <S.textCard><b>CNPJ:</b> 45.123.168/0001-22</S.textCard>
                                <br></br> 
                                <S.textCard><b>Agência:</b> 4268</S.textCard>
                                <br></br> 
                                <S.textCard><b>Conta:</b> 13.005806-9</S.textCard>
                                <br></br> 
                            </S.card>
                </Col>

    
                </Row>

                </S.contBank>
                </S.section>

                <Footer />

            </S.body>

        </>


    );
}


