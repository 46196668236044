import React from 'react';
import styled from 'styled-components';
import { FaBars, FaUserCircle, } from 'react-icons/fa';
import { FiLogOut, FiSettings } from 'react-icons/fi';



export const sectionFooter = styled.div`
    position: relative;
    margin-top:100px;
    height: 120px;
    width:100%;
    justify-content: center;
    text-align: center;
    bottom: 0;
    left: 0;
    background-color: #535252
`
export const textFooter = styled.text`
font-weight: bold;
color: #FFF;
text-aling:center;
`