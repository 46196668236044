import React, { Component, useState, useEffect, useCallback } from "react";
import { Link, useParams } from 'react-router-dom';
import *  as S from './style';
import Divider from "../../../../components/divider";
import { useNavigate, } from "react-router-dom";
import { useAuth } from "../../../../hooks/Authetication";
// import { Pagination } from "react-bootstrap";
import { Container, Row, Col, Table, Button, Form, InputGroup, Tab, Tabs } from 'react-bootstrap';
import moment from "moment";
import { parseCookies } from "nookies";
import formatCnpjCpf from "../../../../utils/maskDocument"
import { MyPagination, Pagination } from "../../../../components/pagination";
import apiexternal from "../../../../utils/api/apiexternal"

export default function Dadosbancario(dadosBanco) {


  const navigate = useNavigate();
  const { user } = useAuth();
  const { id } = useParams();
  const [total, setTotal] = useState(0);
  const [listaBancos, setListaBancos] = useState([]);
  const [lista, setLista] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(25);
  const [showLista, setShowLista] = useState([]);
  const [dataApi, setDataApi] = useState([]);


  async function getBancos() {
    const listBancos = await apiexternal.get('https://guibranco.github.io/BancosBrasileiros/data/bancos.json');
    setListaBancos(listBancos.data);
  }

   function findBankjson(cod) {
    if (cod) {
      const bank =  listaBancos.filter(banco => banco.COMPE == cod);

      console.log(bank[0].LongName)
      return bank[0].LongName;
    } else {
      return '';
    }
  }

  useEffect(() => {

    getBancos();

    setShowLista(dadosBanco.data);
    var lista = [];

    dadosBanco.data.forEach((item, index) => {
      lista.push(item)
    });
    setTotal(lista.length);
    console.log(lista.length)
    lista.forEach((item, index) => {
      Object.assign(item, {
        id: index + 1
      });
    });

    setDataApi(lista);

    setLista(lista);
  }, [dadosBanco.data]);

  useEffect(() => {
    generatePagination(lista);
  }, [page, lista]);


  const generatePagination = useCallback((lista) => {
    setTotal(lista.length);
    const pageStart = (page - 1) * perPage;
    const pageEnd = pageStart + perPage;

    const newLista = lista.slice(pageStart, pageEnd);

    setShowLista(newLista);
  }, [page, perPage]);


  const changeListLista = useCallback((numberPage) => {
    setPage(numberPage);

    const pageStart = (page - 1) * perPage;
    const pageEnd = pageStart + perPage;

    const newLista = lista.slice(pageStart, pageEnd);
    setShowLista(newLista);
  }, [lista, page, perPage]);


  return (
    <>
      <br></br>
      <br></br>

      <h5> Bancos Cadastrados</h5>
      <Button variant="success" onClick={() => alert('add')}> <S.FSchool />  Novo Banco</Button>
      <br></br>
      <br></br>

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>#</th>
            <th>Cod Bancários</th>
            <th>Banco</th>
            <th>Agência</th>
            <th>Conta</th>
            <th>Dígito</th>
            <th>Remover</th>


          </tr>
        </thead>
        <tbody>
          {showLista.map((item) => (
            <tr key={item.agencia}>
              <td>{item.id}</td>

              <td>{item.cob}</td>
              <td>{findBankjson(item.cob)}</td>
              <td>{item.agencia}</td>

              <td>{item.conta}</td>
              <td>{item.digito}</td>
              <center>  <td onClick={() => navigate(`/fornecedor/${item.token}`)} ><S.FRemove /></td></center>


            </tr>
          ))}
        </tbody>
      </Table>
      <MyPagination
        totalCountOfRegisters={total}
        currentPage={page}
        registerPerPage={perPage}
        onPageChange={changeListLista}
      />
    </>


  );
}


