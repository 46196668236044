import React, { Component, useState, useEffect } from "react";
import { Link, useParams } from 'react-router-dom';
import *  as S from './style';
import "./index.css";
import Imglogo from '../../img/logo.png'
import bg from '../../img/bg01.jpg'
import Menu from "../../components/menu";
import Main from "../../components/main";
import Footer from "../../components/footer";
import Divider from "../../components/divider";
import ilikeApi from "../../utils/api/ilikepay";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/Authetication";
import Loading from "../../components/loading";
import { useAlerta } from "../../hooks/alerta";

import Cabecalho from "../../components/cabecalho";

import { Container, Row, Col, Table, Modal, Button, Form } from 'react-bootstrap';
import { getElementError } from "@testing-library/react";




export default function OpenLote() {

    const { id } = useParams();
    console.log(id);
    const [dataApi, setDataApi] = useState([]);
    const [modalShow, setModalShow] = useState(false);
    const [labelLogin, setLabelLogin] = useState("");
    const [labelPsw, setLabelPsw] = useState("");
    const [btAprovLote, setBtAproveLote] = useState(true);

    const [load, setLoad] = useState(false);
    const { showAlert, hideAlert, showAlertTime } = useAlerta();


    const { user } = useAuth()
    console.log(user)


    const navigate = useNavigate();

    useEffect(() => {

        async function chamaapi() {

            var data = JSON.stringify({
                "uuid": user.uuid,
                "hash_lote": id
            });

            var axiosConfig = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.access_token}`,

                }
            };


            try {

                const resposta = await ilikeApi.post("/pag-remessa/open-lote", data, axiosConfig);
                if (resposta.data.error === 0) {
                    console.log(resposta.data.res)
                    setDataApi(resposta.data.res);
                    if (resposta.data.res[0].SJ1_APROVACAO) {
                        setBtAproveLote(false);
                    }


                }
            } catch (error) {



            }


        }

        chamaapi();

    }, []);


    async function aproveLote() {

        setLoad(true);
        setModalShow(false)
        var token1 = document.getElementsByName("token1")[0].value
        var token2 = document.getElementsByName("token2")[0].value

        var data = JSON.stringify({
            "uuid": user.uuid,
            "hash_lote": id,
            "token1": token1,
            "token2": token2,
            "aprove": true
        });

        var axiosConfig = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.access_token}`,
            }
        };

        try {

            const resposta = await ilikeApi.post("/pag-remessa/aprove-lote", data, axiosConfig);
            console.log(resposta.data)
            if (resposta.data.error === '0') {
                showAlertTime({ title: "Realizado", message: "Lote aprovado com sucesso", type: "success" })
                setLoad(false);
                setDataApi([]);

            } else {
                throw new Error(String(resposta.data.msg))
            }
        } catch (error) {
            showAlertTime({ title: "Atenção", message: String(error), type: "danger" })
            setLoad(false);


        }


    }


    function MyVerticallyCenteredModal(props) {
        return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Aprovação de lote de pagamentos via remessa
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4>Confirmação</h4>
                    <p>
                        Declaro que estou de acordo em aprovar o lote para pagamento e tenho total responsabilidade por todos os pagamentos que serão liquidados nesta remessa.
                    </p>

                    <p>
                        Para confimar confirme com o Token enviado em seu E-mail Ex: 0000-0000
                        sendo Token 1 - Token 2
                    </p>
                    <Form>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="1">
                                    <Form.Control type="number" maxLength="4" name='token1' placeholder="Token 1" />


                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3" controlId="2">
                                    <Form.Control type="number" maxLength="4" name='token2' placeholder="Token 2" />
                                </Form.Group>
                            </Col>


                        </Row>
                    </Form>



                    <small> Id do lote: {id} </small>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-success btn-sm btn-block" onClick={() => aproveLote()}>  <S.FaCheckLo /> Aceito</button>

                    <Button type="button" className="btn btn-danger btn-sm btn-block" onClick={props.onHide}>Não aceito</Button>
                </Modal.Footer>
            </Modal>
        );
    }


    return (
        <>
            <Menu />
            <S.body>
                {load ? (
                    <Loading msg="Estamos proecessando a sua solicitação" />
                ) : (
                    <>
               <Cabecalho title={'Lote'} />
                        <Divider />
                        <S.section>


                            <Row>

                                <S.cardTable>
                                    <S.textTitleTble>Títulos</S.textTitleTble>


                                    <Table striped size="sm" responsive>
                                        <thead>
                                            <tr>
                                                <th>Título</th>
                                                <th>Recebedor</th>
                                                <th>Valor</th>
                                                <th>Dt. Vencimento</th>
                                                <th>Remover</th>
                                                <th>Status</th>
                                                <th>Msg</th>



                                            </tr>
                                        </thead>
                                        <tbody>
                                            {dataApi.map((item) => (
                                                <tr key={item.SJ3_NUM}>
                                                    <td>{item.SJ3_NUM}</td>
                                                    <td>{item.SJ4_NM_RECEBEDOR}</td>
                                                    <td> R$ {item.SJ3_VALOR_TITULO}</td>
                                                    <td>{item.SJ3_DT_VENCIMENTO}</td>
                                                    <td>
                                                        {item.SJ3_DT_VENCIMENTO === 1 ? (<button type="button" className="btn btn-link btn-sm btn-block" onClick={() => alert(`Função Indisponivel`)}>  <S.FaRemove /></button>)
                                                            : (<button type="button" className="btn btn-link btn-sm btn-block" onClick={() => alert(`Função Indisponivel`)}>  <S.FaRemoveDis /></button>)}
                                                    </td>
                                                    <td>
                                                        {item.SJ7_NUM === '1' && (<> <div style={{ width: "15px", height: "15px", borderRadius: "50%", backgroundColor: "yellow", margin: "15px", }} title={item.SJ8_DESC}> </div></>)}
                                                        {item.SJ7_NUM === '2' && (<> <div style={{ width: "15px", height: "15px", borderRadius: "50%", backgroundColor: "green", margin: "15px", }} title={item.SJ8_DESC}> </div></>)}
                                                        {item.SJ7_NUM === '3' && (<> <div style={{ width: "15px", height: "15px", borderRadius: "50%", backgroundColor: "orange", margin: "15px", }} title={item.SJ8_DESC}> </div></>)}
                                                        {item.SJ7_NUM === '4' && (<> <div style={{ width: "15px", height: "15px", borderRadius: "50%", backgroundColor: "gray", margin: "15px", }} title={item.SJ8_DESC}> </div></>)}
                                                        {item.SJ7_NUM === '5' && (<> <div style={{ width: "15px", height: "15px", borderRadius: "50%", backgroundColor: "gray", margin: "15px", }} title={item.SJ8_DESC}> </div></>)}
                                                        {item.SJ7_NUM === '6' && (<> <div style={{ width: "15px", height: "15px", borderRadius: "50%", backgroundColor: "gray", margin: "15px", }} title={item.SJ8_DESC}> </div></>)}
                                                        {item.SJ7_NUM === '7' && (<> <div style={{ width: "15px", height: "15px", borderRadius: "50%", backgroundColor: "gray", margin: "15px", }} title={item.SJ8_DESC}> </div></>)}
                                                        {item.SJ7_NUM === '8' && (<> <div style={{ width: "15px", height: "15px", borderRadius: "50%", backgroundColor: "red", margin: "15px", }} title={item.SJ8_DESC}> </div></>)}
                                                        {item.SJ7_NUM === '9' && (<> <div style={{ width: "15px", height: "15px", borderRadius: "50%", backgroundColor: "red", margin: "15px", }} title={item.SJ8_DESC}> </div></>)}
                                                        {item.SJ7_NUM === '10' && (<> <div style={{ width: "15px", height: "15px", borderRadius: "50%", backgroundColor: "red", margin: "15px", }} title={item.SJ8_DESC}> </div></>)}
                                                        {item.SJ7_NUM === '11' && (<> <div style={{ width: "15px", height: "15px", borderRadius: "50%", backgroundColor: "red", margin: "15px", }} title={item.SJ8_DESC}> </div></>)}
                                                    </td>
                                                    <td>
                                                        {item.SJ8_DESC}
                                                    </td>


                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                    {
                                        btAprovLote && (
                                            <button type="button" className="btn btn-success btn-sm btn-block" onClick={() => setModalShow(true)}>  <S.FaCheckLo /> Aprovar Lote</button>
                                        )}
                                                                            <br></br>
                                                                            <br></br>


                                    <h5> Legenda:</h5>
                                    <hr/>
                               
                                    <div>

                                        <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '20px', marginTop: '10px', justifyContent: 'start', align: 'center' }}> Pendente de aprovação: <div style={{ width: "10px", height: "10px", borderRadius: "50%", backgroundColor: "yellow", margin: "5px",  }} /> </div>
                                        <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '20px', marginTop: '10px', justifyContent: 'start', align: 'center' }}> Aprovado para pagamento: <div style={{ width: "10px", height: "10px", borderRadius: "50%", backgroundColor: "green", margin: "5px", }} /> </div>
                                        <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '20px', marginTop: '10px', justifyContent: 'start', align: 'center' }}> Arquivo enviado para pagamento: <div style={{ width: "10px", height: "10px", borderRadius: "50%", backgroundColor: "orange", margin: "5px", }} /> </div>
                                        <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '20px', marginTop: '10px', justifyContent: 'start', align: 'center' }}> Recebido / Realizado: <div style={{ width: "10px", height: "10px", borderRadius: "50%", backgroundColor: "gray", margin: "5px", }} /> </div>
                                        <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '20px', marginTop: '10px', justifyContent: 'start', align: 'center' }}> Pagamento com erro: <div style={{ width: "10px", height: "10px", borderRadius: "50%", backgroundColor: "red", margin: "5px", }} /> </div>

                                    </div>



                                    <MyVerticallyCenteredModal
                                        show={modalShow}
                                        onHide={() => setModalShow(false)}
                                    />
                                </S.cardTable>


                            </Row>
                        </S.section>
                    </>
                )
                }
                <Footer />
            </S.body >


        </>


    );
}


