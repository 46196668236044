import React, { Component, useState, useEffect, useCallback } from "react";
import { Link } from 'react-router-dom';
import *  as S from './style';
import "./index.css";
import Imglogo from '../../img/logo.png'
import bg from '../../img/bg01.jpg'
import Menu from "../../components/menu";
import Main from "../../components/main";
import Footer from "../../components/footer";
import Divider from "../../components/divider";
import ilikeApi from "../../utils/api/ilikepay";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/Authetication";
// import { Pagination } from "react-bootstrap";
import { Container, Row, Col, Table, Button, Form } from 'react-bootstrap';
import { MyPagination, Pagination } from "../../components/pagination";
import moment from "moment";
import { parseCookies } from "nookies";
import {  FaFileExcel } from 'react-icons/fa';
import Cabecalho from "../../components/cabecalho";




export default function Extrato() {
    const [dataApi, setDataApi] = useState([]);

    const navigate = useNavigate();
    const { user } = useAuth();
    const [total, setTotal] = useState(0);
    const [lista, setLista] = useState([]);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(25);
    const [showLista, setShowLista] = useState([]);
    const [datai, setDatai] = useState(null);
    const [dataf, setDataf] = useState(null);

    async function printExcel() {
        const cookies = parseCookies();

        const data = JSON.stringify({
            "metodo": "EX",
            "data": dataApi
        });
        var dateNow = Date.now();
        const numero_aleatorio = Math.floor(Math.random() * 10 + 1);
        const response = await ilikeApi.post(`financeiro-consulta/consultas`, data);

// console.log(response.data);
//         return ;

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `rel${dateNow}.xls`); //or any other extension
        document.body.appendChild(link);
        link.click();
    }

    useEffect(() => {
        generatePagination(lista);
    }, [page, lista]);


    const generatePagination = useCallback((lista) => {
        setTotal(lista.length);
        const pageStart = (page - 1) * perPage;
        const pageEnd = pageStart + perPage;

        const newLista = lista.slice(pageStart, pageEnd);

        setShowLista(newLista);
    }, [page, perPage]);

    const changeListLista = useCallback((numberPage) => {
        setPage(numberPage);

        const pageStart = (page - 1) * perPage;
        const pageEnd = pageStart + perPage;

        const newLista = lista.slice(pageStart, pageEnd);
        setShowLista(newLista);
    }, [lista, page, perPage]);

    async function chamaapi() {
        setDataApi([]);

        setLista([]);
        setTotal(0);
        setPage(1)

        var data = JSON.stringify({
            "uuid": user.uuid,
            "data_inicio": datai,
            "data_fim": dataf,
            "status": null
        });


        // var axiosConfig = {
        //     headers: {
        //         'Content-Type': 'application/json',
        // 'Authorization': `Bearer ${user.access_token}`,


        //     }
        // };

        // 'Authorization': `Bearer ${user.token}`,
        var formdata = new FormData();
        formdata.append("access_token", `${user.access_token}`);
        if (datai) { formdata.append("data_inicio", datai) };
        if (dataf) { formdata.append("data_fim", dataf) };



        try {
            const resposta = await ilikeApi.post(`/banking/consulta-extrato/${user.uuid}`, formdata);
            console.log(resposta.data);


            // var lista = [];

            // resposta.data.movimentacao.itens.forEach((item, index) => {

            //     item.forEach((item, index) => {

            //         lista.push(item)
            //     })

            // });

            // setTotal(lista.length);
            // lista.forEach((item, index) => {
            //     Object.assign(item, {
            //         id: index + 1
            //     });
            // });

            setDataApi(resposta.data.movimentacao.itens);

            setLista(resposta.data.movimentacao.itens);
        } catch (error) {

            console.log(error);
        }



    }

    useEffect(() => {



        chamaapi();

    }, []);


    return (
        <>
            <Menu />
            <S.body>
            <Cabecalho title={'Extrato'} />


                <Divider />
                <S.section>


                    <Row>
                    
                        <Col md={3}>


                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Label>Data Ínicio</Form.Label>
                                <input name="requested_order_ship_date"
                                    // value={moment(datai).format("DD-MMM-YYYY")}
                                    onChange={e => setDatai(e.target.value)}
                                    type="date" className="form-control" />
                            </Form.Group>



                        </Col>
                        <Col md={3}>

                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Label>Data Final</Form.Label>

                                <input name="requested_order_ship_date"
                                    // value={moment('22-02-2022').format("DD-MMM-YYYY")}
                                    onChange={e => setDataf(e.target.value)}

                                    type="date" className="form-control" />
                            </Form.Group>




                        </Col>
                        <Col md={12} >
                            <br></br>
                            <div style={{  display: 'flex', justifyContent: "space-between"}}> 
                            <Button onClick={() => chamaapi()} > Filtrar</Button>

                            <Button variant="success" onClick={()=> printExcel()}> <S.FileExcel /> </Button>
                            </div>
                        </Col>







                        <Col md={12}>

                            <br></br>
                            <br></br>

                            <S.cardTable>
                                <h5>Extrato</h5>
                                <Table striped size="sm" responsive>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Data Movimento</th>
                                            <th>Descrição</th>
                                            <th>Valor</th>
                                            <th>Saldo</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {showLista.map((item) => (
                                            <tr key={item.id}>
                                                <td>{item.id}</td>
                                                <td>   {moment(item.data_movimento).format("DD/MM/YYYY")}</td>
                                                <td>

                                                    <small> {item.descricao} </small>

                                                    {item.natureza === 'D' ? (
                                                        <p> <small> {item.favorecido.nome.toLowerCase()} </small></p>
                                                    ) : (
                                                        <p> <small> {item.remetente.nome.toLowerCase()} </small></p>
                                                    )}
                                                </td>

                                                {item.natureza === 'D' ? (
                                                    <td style={{ color: "#FF0000" }}>R$ - {item.valor}</td>
                                                ) : (
                                                    <td style={{ color: "#006400" }}>R$  {item.valor}</td>
                                                )}
                                                <td> R$ {item.saldo}</td>

                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>

                                <MyPagination
                                    totalCountOfRegisters={total}
                                    currentPage={page}
                                    registerPerPage={perPage}
                                    onPageChange={changeListLista}
                                />

                            </S.cardTable>

                        </Col>
                    </Row>
                </S.section>

                <Footer />
            </S.body>

        </>


    );
}


