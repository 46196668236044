import React, { useState } from "react";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import * as S from '../../style.js'
import Logo from '../../../../img/lgMIN.png'
import Navbar from 'react-bootstrap/Navbar'
import { Dropdown, FormControl } from 'react-bootstrap';

import { useAuth } from "../../../../hooks/Authetication.js";


const ToolBar = ({ openSidebar }) => {

  const navigate = useNavigate();
  const { signOut } = useAuth();

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </a>
  ));

  // forwardRef again here!
  // Dropdown needs access to the DOM of the Menu to measure it
  const CustomMenu = React.forwardRef(
    ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
      const [value, setValue] = useState('');

      return (
        <div
          ref={ref}
          style={style}
          className={className}
          aria-labelledby={labeledBy}
        >
          <ul className="list-unstyled">
            {React.Children.toArray(children).filter(
              (child) =>
                !value || child.props.children.toLowerCase().startsWith(value),
            )}
          </ul>
        </div>
      );
    },
  );

  return (

    <>

      <div className="tool-bar">
        <S.toolBar>
          <div className="burguer" onClick={() => { openSidebar() }}>
            <S.FaIconBar name="FaPushed" />
          </div>
        </S.toolBar>

        <S.toolBar>
          <S.containerLogoToolBar>
            <div className="title">
              <S.headerLogoToolBar src={Logo} />
            </div>
          </S.containerLogoToolBar>
        </S.toolBar>

        <S.toolBar>


          <S.headerConfirToolBar>
            <Dropdown style={{zIndex:'1'}}>
              <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                <S.FaIconUser />
              </Dropdown.Toggle>
              <Dropdown.Menu as={CustomMenu}>
                <Dropdown.Item eventKey="1">Meus dados</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>


            <Dropdown>
              <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                <S.FaIconConf />
              </Dropdown.Toggle>
              <Dropdown.Menu as={CustomMenu}>
                <Dropdown.Item eventKey="2">Trocar Senha</Dropdown.Item>
                {/* <Dropdown.Item eventKey="2">Blue</Dropdown.Item>
                <Dropdown.Item eventKey="1">Red-Orange</Dropdown.Item> */}
              </Dropdown.Menu>
            </Dropdown>
            <S.FaIconSair onClick={()=> signOut(true)} />
          </S.headerConfirToolBar>



        </S.toolBar>





      </div>

    </>
  );
};

export default ToolBar;