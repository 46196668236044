import React from 'react';
import styled  from 'styled-components';







export const MainDiv = styled.div`

`


export const headerLogo = styled.img`
    width: 100pxx;
    height: 100px;
    margin: 0px;
    width:100%
`


export const cardLogin = styled.div`
    margin-top:40px;
`





