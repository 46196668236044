import React, { Component, useState, useEffect, useCallback } from "react";
import { Link, useParams } from 'react-router-dom';
import *  as S from './style';
import "./index.css";
import Imglogo from '../../img/logo.png'
import Menu from "../../components/menu";
import Footer from "../../components/footer";
import Divider from "../../components/divider";
import ilikeApi from "../../utils/api/ilikepay";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/Authetication";
// import { Pagination } from "react-bootstrap";
import { Container, Row, Col, Table, Button, Form, InputGroup } from 'react-bootstrap';
import { MyPagination, Pagination } from "../../components/pagination";
import Cabecalho from "../../components/cabecalho";
import formatCnpjCpf from "../../utils/maskDocument"
import apiexternal from "../../utils/api/apiexternal"

import "./index.css";

export default function Comprovante() {
    const [dataApi, setDataApi] = useState([]);
    const { id } = useParams();
    const [listaBancos, setListaBancos] = useState([]);

    async function getBancos() {
        const listBancos = await apiexternal.get('https://guibranco.github.io/BancosBrasileiros/data/bancos.json');
        setListaBancos(listBancos.data);
      }
    
       function findBankjson(cod) {
        if (cod) {
          const bank =  listaBancos.filter(banco => banco.COMPE == cod);
    
          console.log(bank[0].LongName)
          return bank[0].LongName;
        } else {
          return '';
        }
      }


    const navigate = useNavigate();
    const { user } = useAuth();

  function  print(){
        window.print();
    }
    async function chamaapi() {
        getBancos();
        var data = JSON.stringify({
            "uuid": user.uuid,
            "metodo": 'COMP',
            "hash": id
        });
        var axiosConfig = {
            headers: {
                'Content-Type': 'application/json',
            }
        };

        ilikeApi.defaults.headers.common['Authorization'] = `Bearer ${String(user.access_token)}`;

        try {
            const resposta = await ilikeApi.post(`/consultas/search`, data, axiosConfig);

            if(resposta.data.error === 0){
            setDataApi(resposta.data.res[0])
            console.log(dataApi)
            }

            

        } catch (error) {

            console.log(error);
        }

    }

    useEffect(() => {

        chamaapi();

    }, []);


    return (
        <>
            <Menu />
            <S.body>
                <Cabecalho title={'Comprovante'} />


                <Divider />

                <S.section>

                <div id="printable">

                    <S.Container>
                        <S.logoComp src={Imglogo} />
                        
                        <div id="noprintable">
                        <S.Fprint  onClick={() => print()}/>
                        </div>
                    </S.Container>
                    <Divider/>

                
                    <br></br>
                    <br></br>

                    <Row>
                        <Col md={4}>
                        <center>  <S.textTitleScreen> Comprovante de Transferência</S.textTitleScreen> </center>
                        <br></br>
                        <br></br>
                   <S.textCompTime> {dataApi.dtbaixa} {dataApi.hrBaixa} </S.textCompTime>
                        <S.Container>
                            <S.textLeft> Pagador</S.textLeft> 
                            <S.textRight> {dataApi.pagagador}</S.textRight> 
                        </S.Container>

                        <S.Container>
                            <S.textLeft> Documento</S.textLeft> 
                            <S.textRight> {formatCnpjCpf(dataApi.docPagador)}</S.textRight> 
                        </S.Container>

                        <S.Container>
                            <S.textLeft> Conta Digital</S.textLeft> 
                            <S.textRight>{dataApi.contaPag} - {dataApi.digito} </S.textRight> 
                        </S.Container>

                        <Divider/>
                        <S.FDocument /><S.textTitleScreen> Documento</S.textTitleScreen>
                        <Divider/>

                        <S.Container>
                            <S.textLeft> Favorecido</S.textLeft> 
                            <S.textRight> {dataApi.favorecido}</S.textRight> 
                        </S.Container>


                        <S.Container>
                            <S.textLeft> Documento</S.textLeft> 
                            <S.textRight> {formatCnpjCpf(dataApi.documento)}</S.textRight> 
                        </S.Container>

                        <S.Container>
                            <S.textLeft> Operação </S.textLeft> 
                            <S.textRight> TED</S.textRight> 
                        </S.Container>

                        <S.Container>
                            <S.textLeft> Valor </S.textLeft> 
                            <S.textRight> R$  {dataApi.vl} </S.textRight> 
                        </S.Container>

                        <S.Container>
                            <S.textLeft> Emissor </S.textLeft> 
                            <S.textRight> {findBankjson(dataApi.cob).toLowerCase()}</S.textRight> 
                        </S.Container>

                        <S.Container>
                            <S.textLeft> Agencia </S.textLeft> 
                            <S.textRight> {dataApi.agencia}</S.textRight> 
                        </S.Container>

                        <S.Container>
                            <S.textLeft> Conta </S.textLeft> 
                            <S.textRight> {dataApi.conta} - {dataApi.digito} </S.textRight> 
                        </S.Container>

                        <S.ContainerFooter>
                        <S.Container>
                            <S.textLeft> Liquidate </S.textLeft> 
                            <S.textRight> I LIKE TECHNOLGY LTDA </S.textRight> 
                        </S.Container>

                        <S.Container>
                            <S.textLeft> CNPJ </S.textLeft> 
                            <S.textRight> 45.123.168/0001-22 </S.textRight> 
                        </S.Container>

                        <S.Container>
                            <S.textLeft>Transação </S.textLeft> 
                        </S.Container>
                        <S.Container>
                            <S.textRight>677738383-37483992929272829292902ks </S.textRight> 
                        </S.Container>


                        </S.ContainerFooter>


                        
                        </Col>
                    </Row>

                    </div>

                </S.section>

                <Footer />
            </S.body>

        </>


    );
}


