import React, { Component, useState, useEffect, useCallback } from "react";
import { Link, useParams } from 'react-router-dom';
import *  as S from './style';
import Divider from "../../../../components/divider";
import { useNavigate, } from "react-router-dom";
import { useAuth } from "../../../../hooks/Authetication";
// import { Pagination } from "react-bootstrap";
import { Container, Row, Col, Table, Button, Form, InputGroup, Tab, Tabs } from 'react-bootstrap';
import moment from "moment";
import { parseCookies } from "nookies";
import formatCnpjCpf from "../../../../utils/maskDocument"
import { MyPagination, Pagination } from "../../../../components/pagination";

export default function DadosPagamentos(dadosPag) {

  const navigate = useNavigate();
  const { user } = useAuth();
  const { id } = useParams();
  const [total, setTotal] = useState(0);
  const [lista, setLista] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(25);
  const [showLista, setShowLista] = useState([]);
  const [dataApi, setDataApi] = useState([]);



  useEffect(() => {
    setShowLista(dadosPag.data);
    var lista = [];

    dadosPag.data.forEach((item, index) => {
      lista.push(item)
    });
    setTotal(lista.length);
    console.log(lista.length)
    lista.forEach((item, index) => {
      Object.assign(item, {
        id: index + 1
      });
    });

    setDataApi(lista);

    setLista(lista);
  }, [dadosPag.data]);


  useEffect(() => {
    generatePagination(lista);
  }, [page, lista]);


  const generatePagination = useCallback((lista) => {
    setTotal(lista.length);
    const pageStart = (page - 1) * perPage;
    const pageEnd = pageStart + perPage;

    const newLista = lista.slice(pageStart, pageEnd);

    setShowLista(newLista);
  }, [page, perPage]);


  const changeListLista = useCallback((numberPage) => {
    setPage(numberPage);

    const pageStart = (page - 1) * perPage;
    const pageEnd = pageStart + perPage;

    const newLista = lista.slice(pageStart, pageEnd);
    setShowLista(newLista);
  }, [lista, page, perPage]);


  return (
    <>
      <br></br>
      <br></br>

      <h5> Pagamentos Realizado</h5>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>#</th>
            <th>Data</th>
            <th>Valor</th>
            <th>Data Pagamento</th>
            <th>Hora Pagamento</th>
            <th>Comprovante</th>


          </tr>
        </thead>
        <tbody>
          {showLista.map((item) => (
            <tr key={item.id}>
              <td>{item.id}</td>

              <td>{item.data_envio}</td>
              <td>{item.vl}</td>
              <td>{item.data_pag}</td>

              <td>{item.hr_pag}</td>
              <center> <td onClick={() => navigate(`/comprovante/${item.token}`)} ><S.Fedit /></td> </center>


            </tr>
          ))}
        </tbody>
      </Table>
      <MyPagination
        totalCountOfRegisters={total}
        currentPage={page}
        registerPerPage={perPage}
        onPageChange={changeListLista}
      />
    </>


  );
}


