// eslint-disable-next-line import/no-anonymous-default-export
export default {
    colors: {
      primary: '#7b2539',
      primary_light: '#FF8FA9',
      primary_more_light: '#ff0266',
      secondary: '#03dac6',
      secondary_light: '#5D24E0',
      success: '#279638',
      success_2:'#68E37B',
      success_light: 'rgba(18, 164, 84, 0.1)',
      attention: '#E91E63',
      attention_2: '#f60058',
      attention_light: 'rgba(232, 63, 91, 0.1)',
      
      shape: '#242424',
      shape_light: '',
      white: '#FFF',
      white_light: '#DDD',
      black: '#000000',
  
  
      title: '#F7FAFC',
      text: 'rgba(255, 255, 255, 0.92)',
      text_dark: '#E2E8F0',
      background: '#000000',
  
      // New Colors
      blue_700: '#272833',
      blue_800: '#1f2029',
      blue_900: '#04070F',
  
      new_blue: '#1d3d4e',
      new_blue_900: '#142129',
      
      gray_200: '#E2E8F0',
      gray_300: '#a3a3a3',
      gray_350: '#9C9CA6',
      gray_400: '#9898A1',
      gray_500: '#626265',
      gray_800: '#202024',
      gray_900: '#121214',
    },
    fonts: {
      regular: 'Montserrat_400Regular',
      medium: 'Montserrat_500Medium',
      semi_bold: 'Montserrat_600SemiBold',
      bold: 'Montserrat_700Bold'
    }
  }