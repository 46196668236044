import React, { Component, useState, useEffect, useCallback } from "react";
import { Link } from 'react-router-dom';
import *  as S from './style';
import "./index.css";
import Imglogo from '../../img/logo.png'
import bg from '../../img/bg01.jpg'
import Menu from "../../components/menu";
import Main from "../../components/main";
import Footer from "../../components/footer";
import Divider from "../../components/divider";
import ilikeApi from "../../utils/api/ilikepay";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/Authetication";
// import { Pagination } from "react-bootstrap";
import { Container, Row, Col, Table, Button, Form, InputGroup } from 'react-bootstrap';
import { MyPagination, Pagination } from "../../components/pagination";
import moment from "moment";
import { parseCookies } from "nookies";
import { FaFileExcel } from 'react-icons/fa';
import Cabecalho from "../../components/cabecalho";
import formatCnpjCpf from "../../utils/maskDocument"


export default function CadastroForn() {
    const [dataApi, setDataApi] = useState([]);

    const navigate = useNavigate();
    const { user } = useAuth();
    const [total, setTotal] = useState(0);
    const [lista, setLista] = useState([]);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(25);
    const [showLista, setShowLista] = useState([]);
    const [datai, setDatai] = useState(null);
    const [dataf, setDataf] = useState(null);
    const [nameSearch, setNameSearch] = useState(null);




    useEffect(() => {
        generatePagination(lista);
    }, [page, lista]);


    const generatePagination = useCallback((lista) => {
        setTotal(lista.length);
        const pageStart = (page - 1) * perPage;
        const pageEnd = pageStart + perPage;

        const newLista = lista.slice(pageStart, pageEnd);

        setShowLista(newLista);
    }, [page, perPage]);





    const changeListLista = useCallback((numberPage) => {
        setPage(numberPage);

        const pageStart = (page - 1) * perPage;
        const pageEnd = pageStart + perPage;

        const newLista = lista.slice(pageStart, pageEnd);
        setShowLista(newLista);
    }, [lista, page, perPage]);

    async function chamaapi() {
        setDataApi([]);

        setLista([]);
        setTotal(0);
        setPage(1)

        var data = JSON.stringify({
            "uuid": user.uuid,
            "metodo": 'SFOR',
            "filName": nameSearch
        });
        var axiosConfig = {
            headers: {
                'Content-Type': 'application/json',
            }
        };

        ilikeApi.defaults.headers.common['Authorization'] = `Bearer ${String(user.access_token)}`;

        try {
            const resposta = await ilikeApi.post(`/consultas/search`, data, axiosConfig);
            console.log(resposta.data.res);


            var lista = [];

            resposta.data.res.forEach((item, index) => {
                lista.push(item)
            });

            setTotal(lista.length);
            console.log(lista.length)
            lista.forEach((item, index) => {
                Object.assign(item, {
                    id: index + 1
                });
            });

            setDataApi(lista);

            setLista(lista);

            console.log(showLista)
        } catch (error) {

            console.log(error);
        }
    }



    function filterByname(value) {

        console.log(value);

        if (value.length == 0) {
            setNameSearch(null);
            chamaapi();
        }

        if (value.length >= 5) {
            setNameSearch(value);
            chamaapi();
        }


    }

    useEffect(() => {

        chamaapi();

    }, []);


    return (
        <>
            <Menu />
            <S.body>
                <Cabecalho title={'Cadastro de fornecedor'} />


                <Divider />
                <S.section>

                    <Button variant="success" onClick={() => alert('add')}> <S.FaUse />  Novo fornecedor</Button>

                    <Row>

                        <Col md={3}>


                            {/* <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Label>Data Ínicio</Form.Label>
                                <input name="requested_order_ship_date"
                                    // value={moment(datai).format("DD-MMM-YYYY")}
                                    onChange={e => setDatai(e.target.value)}
                                    type="date" className="form-control" />
                            </Form.Group> */}



                        </Col>
                        <Col md={3}>

                            {/* <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Label>Data Final</Form.Label>

                                <input name="requested_order_ship_date"
                                    // value={moment('22-02-2022').format("DD-MMM-YYYY")}
                                    onChange={e => setDataf(e.target.value)}

                                    type="date" className="form-control" />
                            </Form.Group> */}




                        </Col>
                        {/* <Col md={12} >
                            <br></br>
                            <div style={{  display: 'flex', justifyContent: "space-between"}}> 
                            <Button onClick={() => chamaapi()} > Filtrar</Button>

                            <Button variant="success" onClick={()=> printExcel()}> <S.FileExcel /> </Button>
                            </div>
                        </Col> */}







                        <Col md={12}>

                            <br></br>
                            <br></br>

                            <S.cardTable>
                                <h5>Lista de Fornecedores</h5>
                                <Col md={2}>


                                    <Form.Label htmlFor="basic-url" >Pesquisar por nome</Form.Label>
                                    <InputGroup className="mb-3">

                                        <Form.Control type="text" placeholder="Pesquisar por nome" onKeyUp={(e) => filterByname(e.target.value)} />
                                        <InputGroup.Text id="basic-addon3">
                                            <S.FSearch />
                                        </InputGroup.Text>
                                    </InputGroup>





                                </Col>
                                <Table striped size="sm" responsive>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Nome</th>
                                            <th>Documento</th>
                                            <th>Editar</th>

                                        </tr>
                                    </thead>

                                    <tbody>
                                        {showLista.map((item) => (
                                            <tr key={item.token}>
                                                <td>{item.id}</td>

                                                <td>{item.nome}</td>
                                                <td>{formatCnpjCpf(item.inscricao)}</td>
                                                <td onClick={() => navigate(`/fornecedor/${item.token}`)} ><S.Fedit /></td>


                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>

                                <MyPagination
                                    totalCountOfRegisters={total}
                                    currentPage={page}
                                    registerPerPage={perPage}
                                    onPageChange={changeListLista}
                                />

                            </S.cardTable>

                        </Col>
                    </Row>
                </S.section>

                <Footer />
            </S.body>

        </>


    );
}


