import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import * as S from './style.js'
import packageJson from "../../../package.json";


export default function Footer() {
    var data = new Date();
    var ano = data.getFullYear();



    return (

        <S.sectionFooter>
            <br></br>

            <S.textFooter> I Like Techonology Copyright © {ano} </S.textFooter>
            <br></br>
            <small> 45.123.168/0001-22 </small>
            <br></br>
            <small>  {packageJson.version} </small>
        </S.sectionFooter>
    )
}
