import React, { Component, useState, useEffect, useCallback } from "react";
import { Link, useParams } from 'react-router-dom';
import *  as S from './style';
import Divider from "../../../../components/divider";
import { useNavigate, } from "react-router-dom";
import { useAuth } from "../../../../hooks/Authetication";
// import { Pagination } from "react-bootstrap";
import { Container, Row, Col, Table, Button, Form, InputGroup, Tab, Tabs } from 'react-bootstrap';
import moment from "moment";
import { parseCookies } from "nookies";
import formatCnpjCpf from "../../../../utils/maskDocument"



export default function DadosCadastro(dadosCliente) {
    const navigate = useNavigate();
    const { user } = useAuth();
    const { id } = useParams();
    const [dataCliente, setDataCliente] = useState([]);


    useEffect(() => {
        if (dadosCliente.data) {
            setDataCliente(dadosCliente.data[0]);

            console.log(dataCliente)
        }
    }, [dadosCliente]);


    return (
        <>
            <Button variant="info" style={{ color: '#FFF' }} type="button" >
                <S.Fedit />   Editar
            </Button>
            <br></br>
            <br></br>

            <Form>
                <Row>
                    <Col md={10}>
                        <Row>
                            <Col md={6}>
                                <Form.Group className="mb-3" controlId="formdis">
                                    <Form.Label>Nome Completo</Form.Label>
                                    <Form.Control type="text" value={dataCliente.nome} placeholder="Nome Completo" disabled />
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group className="mb-3" >
                                    <Form.Label>Token</Form.Label>
                                    <Form.Control type="text" value={dataCliente.token} disabled />
                                </Form.Group>
                            </Col>

                            <Col md={5}>
                                <Form.Group className="mb-6" controlId="formdis">
                                    <Form.Label>Documento</Form.Label>
                                    <Form.Control value={formatCnpjCpf(dataCliente.documento)} type="text" disabled />
                                </Form.Group>
                            </Col>
                            <Divider />
                        </Row>

                    </Col>
                </Row>
                <h5>Endereço</h5>

                <Row>
                    <Col md={10}>
                        <Row>
                            <Col md={8}>
                                <Form.Group className="mb-3" controlId="formdis">
                                    <Form.Label>Lagadouro</Form.Label>
                                    <Form.Control type="text" value={dataCliente.endereco} placeholder="Nome Completo" disabled />
                                </Form.Group>
                            </Col>
                            <Col md={3}>
                                <Form.Group className="mb-3" controlId="formdis">
                                    <Form.Label>Nº</Form.Label>
                                    <Form.Control type="text" value={dataCliente.num} disabled />
                                </Form.Group>
                            </Col>

                            <Col md={3}>
                                <Form.Group className="mb-3" controlId="formdis">
                                    <Form.Label>Complemento</Form.Label>
                                    <Form.Control value={dataCliente.comp} type="text" disabled />
                                </Form.Group>
                            </Col>
                            <Col md={3}>
                                <Form.Group className="mb-3" controlId="formdis">
                                    <Form.Label>Cep</Form.Label>
                                    <Form.Control value={dataCliente.cep} type="text" disabled />
                                </Form.Group>
                            </Col>
                            <Col md={3}>
                                <Form.Group className="mb-3" controlId="formdis">
                                    <Form.Label>Bairro</Form.Label>
                                    <Form.Control value={dataCliente.bairro} type="text" disabled />
                                </Form.Group>
                            </Col>
                            <Col md={3}>
                                <Form.Group className="mb-3" controlId="formdis">
                                    <Form.Label>Cidade</Form.Label>
                                    <Form.Control value={dataCliente.cidade} type="text" disabled />
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group className="mb-3" controlId="formdis">
                                    <Form.Label>Estado</Form.Label>
                                    <Form.Select aria-label="Default select example" id="ddlViewBy">

                                        {
                                            dataCliente.estado === 'SP' ?
                                                (<>  <option value="SP" selected>São Paulo</option></>)
                                                :
                                                (<><option value="SP" >São Paulo</option></>)
                                        }

                                        {
                                            dataCliente.estado === 'AC' ?
                                                (<><option value="AC" selected>Acre</option></>)
                                                :
                                                (<><option value="AC" >Acre</option></>)
                                        }

                                        {
                                            dataCliente.estado === 'AL' ?
                                                (<><option value="AL" selected>Alagoas</option></>)
                                                :
                                                (<><option value="AL" >Alagoas</option></>)
                                        }

                                        {
                                            dataCliente.estado === 'AP' ?
                                                (<><option value="AP" selected>Amapá</option></>)
                                                :
                                                (<><option value="AP" >Amapá</option></>)
                                        }

                                        {
                                            dataCliente.estado === 'AM' ?
                                                (<><option value="AM" selected>Amazonas</option></>)
                                                :
                                                (<><option value="AM" >Amazonas</option></>)
                                        }

                                        {
                                            dataCliente.estado === 'BA' ?
                                                (<><option value="BA" selected>Bahia</option></>)
                                                :
                                                (<><option value="BA" >Bahia</option></>)
                                        }

                                        {
                                            dataCliente.estado === 'CE' ?
                                                (<><option value="CE" selected>Ceará</option></>)
                                                :
                                                (<><option value="CE" >Ceará</option></>)
                                        }


                                        {
                                            dataCliente.estado === 'DF' ?
                                                (<><option value="DF" selected>Distrito Federal</option></>)
                                                :
                                                (<><option value="DF" >Distrito Federal</option></>)
                                        }


                                        {
                                            dataCliente.estado === 'ES' ?
                                                (<><option value="ES" selected>Espírito Santo</option></>)
                                                :
                                                (<><option value="ES" >Espírito Santo</option></>)
                                        }


                                        {
                                            dataCliente.estado === 'GO' ?
                                                (<><option value="GO" selected>Goiás</option></>)
                                                :
                                                (<><option value="GO" >Goiás</option></>)
                                        }


                                        {
                                            dataCliente.estado === 'MA' ?
                                                (<><option value="MA" selected>Maranhão</option></>)
                                                :
                                                (<><option value="MA" >Maranhão</option></>)
                                        }


                                        {
                                            dataCliente.estado === 'MT' ?
                                                (<><option value="MT" selected>Mato Grosso</option></>)
                                                :
                                                (<><option value="MT" >Mato Grosso</option></>)
                                        }


                                        {
                                            dataCliente.estado === 'MS' ?
                                                (<><option value="MS" selected>Mato Grosso do Sul</option></>)
                                                :
                                                (<><option value="MS" >Mato Grosso do Sul</option></>)
                                        }

                                        {
                                            dataCliente.estado === 'MG' ?
                                                (<><option value="MG" selected>Minas Gerais</option></>)
                                                :
                                                (<><option value="MG" >São Paulo</option></>)
                                        }

                                        {
                                            dataCliente.estado === 'PA' ?
                                                (<><option value="PA" selected>Pará</option></>)
                                                :
                                                (<><option value="PA" >Pará</option></>)
                                        }

                                        {
                                            dataCliente.estado === 'PB' ?
                                                (<><option value="PB" selected>Paraíba</option></>)
                                                :
                                                (<><option value="PB" >Paraíba</option></>)
                                        }

                                        {
                                            dataCliente.estado === 'PR' ?
                                                (<><option value="PR" selected>Paraná</option></>)
                                                :
                                                (<><option value="PR" >Paraná</option></>)
                                        }


                                        {
                                            dataCliente.estado === 'PE' ?
                                                (<><option value="PE" selected>Pernambuco</option></>)
                                                :
                                                (<><option value="PE" >Pernambuco</option></>)
                                        }


                                        {
                                            dataCliente.estado === 'PI' ?
                                                (<><option value="PI" selected>Piauí</option></>)
                                                :
                                                (<><option value="PI" >Piauí</option></>)
                                        }


                                        {
                                            dataCliente.estado === 'RJ' ?
                                                (<><option value="RJ" selected>Rio de Janeiro</option></>)
                                                :
                                                (<><option value="RJ" >Rio de Janeiro</option></>)
                                        }


                                        {
                                            dataCliente.estado === 'RN' ?
                                                (<><option value="RN" selected>Rio Grande do Norte</option></>)
                                                :
                                                (<><option value="RN" >Rio Grande do Norte</option></>)
                                        }


                                        {
                                            dataCliente.estado === 'RS' ?
                                                (<><option value="RS" selected>Rio Grande do Sul</option></>)
                                                :
                                                (<><option value="RS" >Rio Grande do Sul</option></>)
                                        }


                                        {
                                            dataCliente.estado === 'RO' ?
                                                (<><option value="RO" selected>Rondônia</option></>)
                                                :
                                                (<><option value="RO" >Rondônia</option></>)
                                        }


                                        {
                                            dataCliente.estado === 'RR' ?
                                                (<><option value="RR" selected>Roraima</option></>)
                                                :
                                                (<><option value="RR" >Roraima</option></>)
                                        }


                                        {
                                            dataCliente.estado === 'SC' ?
                                                (<><option value="SC" selected>Santa Catarina</option></>)
                                                :
                                                (<><option value="SC" >Santa Catarina</option></>)
                                        }


                                        {
                                            dataCliente.estado === 'SE' ?
                                                (<><option value="SE" selected>Sergipe</option></>)
                                                :
                                                (<><option value="SE" >Sergipe</option></>)
                                        }


                                        {
                                            dataCliente.estado === 'TO' ?
                                                (<><option value="TO" selected>Tocantins</option></>)
                                                :
                                                (<><option value="TO" >Tocantins</option></>)
                                        }



                                    </Form.Select>                                </Form.Group>
                            </Col>



                            <Divider />
                        </Row>

                    </Col>
                </Row>



                <Button variant="success" type="submit">
                    <S.FSend />     Salvar
                </Button>
            </Form>

        </>


    );
}


