import React, { Component, useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import *  as S from './style';
import "./index.css";
import Imglogo from '../../img/logo.png'
import bg from '../../img/bg01.jpg'
import Menu from "../../components/menu";
import Main from "../../components/main";
import Footer from "../../components/footer";
import Divider from "../../components/divider";
import ilikeApi from "../../utils/api/ilikepay";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/Authetication";
import Cabecalho from "../../components/cabecalho";


import { Container, Row, Col, Table } from 'react-bootstrap';




export default function ListaLotes() {

    const [dataApi, setDataApi] = useState([]);
    const navigate = useNavigate();
    const { user } = useAuth();
    useEffect(() => {

        async function chamaapi() {


            var data = JSON.stringify({
                "uuid": user.uuid,
                "periodo_inicio": null,
                "periodo_fim": null,
                "status": null
            });

            var axiosConfig = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.access_token}`,

                }
            };



            const resposta = await ilikeApi.post("/pag-remessa/lista-lotes", data, axiosConfig);
            if (resposta.data.error === 0) {

                setDataApi(resposta.data.res);

            }
            console.log(resposta.data)

        }

        chamaapi();

    }, []);


    return (    
        <>
            <Menu />
            <S.body>
            <Cabecalho title={'Lista de lotes'} />

                <Divider />
                <S.section>

                    <Row>
                        <Col md={12}>
                            <S.cardTable>
                                <h5>Lotes disponíveis</h5>

                                <Table striped size="sm" responsive>
                                    <thead>
                                        <tr>
                                            <th>Lote</th>
                                            <th>Quantidade de Títulos</th>
                                            <th>Data</th>
                                            <th>Hora</th>
                                            <th>Status</th>
                                            <th>Acessar</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {dataApi.map((item) => (
                                            <tr key={item.SJ1_NUM}>
                                                <td>{item.SJ1_NUM}</td>
                                                <td>{item.Quantidade}</td>
                                                <td>{item.SJ1_DT}</td>
                                                <td>{item.SJ1_HR}</td>
                                                <td> 
                                                    {item.SJ1_APROVACAO === '0' ?(  <p style={{color: "#006B04"}}> Liberado </p>  ) : (<p style={{color: "#EB5F00"}}> Pendente </p>)} 
                                                </td> 
                                                <td>  <button type="button" className="btn btn-success btn-sm btn-block" onClick={() => navigate(`/openLote/${item.SJ1_LOTE_HASH}`)}> <text> <S.FaFolderO /> Abrir </text></button> { }</td>

                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </S.cardTable>
                        </Col>
                    </Row>
                </S.section>
                <Footer />
            </S.body>

        </>


    );
}


