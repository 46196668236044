import React, { useState } from 'react';
import axios from "axios";



// const Att = () => {
//   axios.defaults.headers.common['Authorization'] = `Bearer ${String(user.access_token)}`;
  
// }


const ilikeApi = axios.create({
    baseURL: 'https://prod-gtw.iliketechnology.com.br/',
    // baseURL: 'https://hml-gtw.iliketechnology.com.br/',

		// headers: {
		// 	'Access-Control-Allow-Origin': '*',
		// 	'Content-Type': 'application/json',
		// }
		// withCredentials: true,

  });

  export default ilikeApi