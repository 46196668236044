import React from "react";
import { Route, Routes } from "react-router-dom";

import Login from "../screen/login";
import Extrato from "../screen/extrato";

import Sobre from "../screen/sobre";
import Dashboard from "../screen/dashboard";
import Consulta from "../screen/consulta";
import ListaLotes from "../screen/listaLotes";
import OpenLote from "../screen/openLote";
import Expire from "../screen/expire";
import { useAuth } from "../hooks/Authetication";
import Forbiden from "../screen/forbiden";
import NotFound from "../screen/notfound";
import CadastroForn from "../screen/cadastroforn";
import DadosFornecedor from "../screen/dadosfornecedor";
import Comprovante from "../screen/comprovante";

// import { useAuth } from "../hooks/Authetication"



export default function MainRoutes() {
    const { user } = useAuth();
    return (
        <Routes>
            {user ? (
                <>
                    <Route path="/" element={<Login />} />
                    <Route path="/login" element={<Login />} />

                    <Route path="/expire" element={ <Expire /> } />
                    

                   <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/consulta" element={<Consulta />} />
                        <Route path="/listaLotes" element={<ListaLotes />} />
                            <Route path="/openLote/:id" element={<OpenLote />} />
                            
                            <Route path="/extrato" element={<Extrato />} />
                            {/* Cadastros  */}
                            <Route path="/cadfornecedor" element={<CadastroForn />} />
                            <Route path="/fornecedor/:id" element={<DadosFornecedor />} />
                            <Route path="/comprovante/:id" element={<Comprovante />} />

                </>
            ) : (
                <>
                    <Route path="/" element={<Login />} />
                    <Route path="/login" element={<Login />} />

                    <Route path="/sobre" element={<Sobre />} />
                    <Route path="/expire" element={ <Expire /> } />
                    <Route path="*" element={<NotFound /> } />
                    

                    {/* As rotas abaixo são de acesso negado */}
                    
                    <Route path="/dashboard" element={<Forbiden /> } />

                    <Route path="/consulta" element={<Forbiden />} />
                        <Route path="/listaLotes" element={<Forbiden />} />
                            <Route path="/openLote/:id" element={<Forbiden />} />

                </>

            )}

                   
             


        </Routes>
    )

}