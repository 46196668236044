import React, { createContext, useContext, useState, useCallback, useEffect } from "react";
import ilikeApi from "../utils/api/ilikepay";
import { useNavigate } from 'react-router-dom';
import { destroyCookie, parseCookies, setCookie } from "nookies";
import { useAlerta } from "./alerta";


const AuthContext = createContext();

function AuthProvider({ children }) {
    const [user, setUser] = useState();
    const { showAlert, hideAlert, showAlertTime } = useAlerta();

    let navigate = useNavigate();

    async function signIn({ cpf, password, tokenExpo }) {
        showAlert({ title: "Aguarde", message: "Enviando suas informações", type: "warning" })

        var qs = require('qs');
        var data = new FormData();
        data.append('login', cpf);
        data.append('password', password);
        data.append('device', tokenExpo);
        try {

            const response = await ilikeApi.post('/login/acesso', data);
            hideAlert();

            if (response.data.erro) {

                throw new Error(String(response.data.msg))
            }

            console.log(response.data.access_token)

            const user = {
                access_token: response.data.access_token,
                name: response.data.user.nome,
                email: response.data.user.email,
                uuid: response.data.user.uuid,
                expires_in: response.data.expires_in
            }
            setUser(user);

            setCookie(undefined, 'client.user', user, {
                maxAge: 60 * 60, // 1 hora
                path: '/'
            });


            setCookie(undefined, 'client.session', true, {
                maxAge: 3600 * 12, // 1 hora
                path: '/'
            });


            saveLocalStorage(user)

            navigate('/dashboard');
            return true;

        } catch (error) {
            showAlertTime({ title: "Atenção", message: String(error), type: "danger" })

        }
    }


    function saveLocalStorage(user) {
        localStorage.setItem('client.user', JSON.stringify(user));
    }

    function getLocalStorage() {
        const user = localStorage.getItem('client.user');
        return user;
    }

    function clearLocalStorage() {
        localStorage.clear();
    }




    const signOut = useCallback(async (click) => {
        const { 'client.session': session } = parseCookies();
        destroyCookie(null, 'client.user');
        clearLocalStorage();
        setUser(null);

        if (click) {
            navigate('/');
        } 
    }, []);


    useEffect(() => {
        const { 'client.user': user } = parseCookies();

        const { 'client.session': session } = parseCookies();

        console.log(`Minha Sessão: ${session}`)

        if (user) {
            const user = getLocalStorage();
            console.log(user)
            setUser(JSON.parse(user));
        } else {
            if (session) {
                navigate('/expire');

            } else {
                signOut()
            }
        }
    }, [navigate]);





    return (
        <AuthContext.Provider value={{ user, signIn, signOut }}>
            {children}
        </AuthContext.Provider>
    )
}
function useAuth() {
    const context = useContext(AuthContext);

    if (!context) {
        throw new Error('UseAuth must be used within as AuthProvider');
    }

    return context;
}

export { AuthProvider, useAuth }