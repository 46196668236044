import React from 'react';
import styled from 'styled-components';
import { FaFolderOpen, FaUser, } from 'react-icons/fa';
import { FiHelpCircle } from 'react-icons/fi';



export const sectionFooter = styled.div`
    width:100%;
    
`

export const FaUse = styled(FaUser)`
font-size:25px;
color: ${({ theme }) => theme.colors.white_light};
padding:3px;
`;

export const nameuser = styled.div`
top:0;
color: ${({ theme }) => theme.colors.white_light} ;
margin-top:-10px;
transition: all 0.3s ease-out;
max-width: 100%;
margin-bottom:20px ;
font-size: 16px;
`
export const textTitleScreen = styled.text`
font-weight: bolder ;
color: ${({ theme }) => theme.colors.white_light};
font-weight: 400;
font-size: 1.75rem;
padding-bottom: 10px;
`
