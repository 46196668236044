import React from 'react';
import styled from 'styled-components';
import { FaFolderOpen, FaUserCircle, FaUser,FaUserPlus, FaFileExcel, FaRegEdit, FaSearch } from 'react-icons/fa';
import { AiOutlinePrinter } from 'react-icons/ai';
import { FcDocument } from 'react-icons/fc';




export const FaFolderO = styled(FaFolderOpen)`
font-size:25px;
padding:3px;
`;


export const FileExcel = styled(FaFileExcel)`
font-size:25px;
padding:3px;
`;

export const FDocument = styled(FcDocument)`
font-size:30px;
filter: grayscale(100%);

`;


export const FaUse = styled(FaUserPlus)`
font-size:25px;
color: ${({ theme }) => theme.colors.white_light};
padding:3px;
`;

export const Fprint = styled(AiOutlinePrinter)`
font-size:35px;
color: ${({ theme }) => theme.colors.primary};
padding:3px;
`;

export const FSearch = styled(FaSearch)`
font-size:25px;
color: ${({ theme }) => theme.colors.white_light};
padding:3px;
`;

export const Fedit = styled(FaRegEdit)`
font-size:25px;
color: ${({ theme }) => theme.colors.primary};
padding:3px;
`;

export const body = styled.div`
    min-height: calc(100vh - 300px);
    top:0;
    padding:30px;
    margin-top:100px;
    border-radius:20px;
    transition: all 0.3s ease-out;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
`

export const nameuser = styled.div`
top:0;
color: ${({ theme }) => theme.colors.white_light} ;
margin-top:-10px;
transition: all 0.3s ease-out;
max-width: 100%;
margin-bottom:20px ;
font-size: 16px;
`

export const section = styled.div`
    border-top: 0.5rem  ${({ theme }) => theme.colors.primary} solid;
    min-height: calc(100vh - 400px);
    top:0;
    padding:30px;
    margin-left:10px;
    margin-right:10px;
    box-shadow: 7px 7px 13px 20px rgba(50, 50, 50, 0.22);
    border-radius:20px;
    transition: all 0.3s ease-out;
    background-color: #FFF;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    border-bottom: 0.5rem  ${({ theme }) => theme.colors.secondary} solid;

`





export const Container = styled.div`
display: flex;
justify-content: space-between;
`

export const ContainerFooter = styled.div`

background-color: #666;
border-radius:5px;
padding: 10px;
`

export const textTitleScreen = styled.text`
font-weight: bolder ;
color: ${({ theme }) => theme.colors.black};
font-weight: 200;
font-size: 1.00rem;
padding-bottom: 10px;
`

export const textCompTime = styled.text`
font-weight: bolder ;
color: ${({ theme }) => theme.colors.black};
font-weight: 200;
font-size: 0.70rem;
padding-bottom: 10px;
`

export const textLeft = styled.text`
font-weight: bolder ;
color: ${({ theme }) => theme.colors.black};
font-weight: 400;
font-size: 0.90rem;
padding-bottom: 10px;
`

export const textRight = styled.text`
font-weight: bolder ;
color: ${({ theme }) => theme.colors.black};
font-weight: 200;
font-size: 0.70rem;
padding-bottom: 10px;
`


export const textCardTitle = styled.text`
font-weight: bolder;
font-size: 16px;
color: #4685c4;
`


export const textCard = styled.text`
font-size: 14px;
`

export const headerLogo = styled.img`
     width: 100px;
    height: 100px;
    margin: 0px;
    width:100%;
`


export const printImg = styled.img`
    width: 40px;
    margin: 0px;
`

export const logoComp = styled.img`
    width: 200px;
    margin: 0px;
    filter: grayscale(100%);
    background-color: #000;
    border-radius: 10px
`



export const cardLogin = styled.div`
    margin-top:40px;
`



