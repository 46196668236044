import React, { Component, useState, useEffect, useCallback } from "react";
import { Link, useParams } from 'react-router-dom';
import *  as S from './style';
import "./index.css";
import Imglogo from '../../img/logo.png'
import bg from '../../img/bg01.jpg'
import Menu from "../../components/menu";
import Main from "../../components/main";
import Footer from "../../components/footer";
import Divider from "../../components/divider";
import ilikeApi from "../../utils/api/ilikepay";
import { useNavigate, } from "react-router-dom";
import { useAuth } from "../../hooks/Authetication";
// import { Pagination } from "react-bootstrap";
import { Container, Row, Col, Table, Button, Form, InputGroup, Tab, Tabs } from 'react-bootstrap';
import moment from "moment";
import { parseCookies } from "nookies";
import Cabecalho from "../../components/cabecalho";
import formatCnpjCpf from "../../utils/maskDocument"
import Dadosbancario from "./components/dadosbancarios"
import DadosCadastro from "./components/dadoscadastrais"
import DadosPagamentos from "./components/dadospagamentos"
export default function DadosFornecedor() {
    const [dataApi, setDataApi] = useState([]);

    const [dataCliente, setDataCliente] = useState([
        
            {
                "token": null,
                "nome": null,
                "documento": '0000000000',
                "endereco": null,
                "num": null,
                "comp": null,
                "bairro": null,
                "cep": null,
                "cidade": null,
                "estado": null
            }
        

    ]);
    const [dataBanco, setDataBanco] = useState([

            {
                "cob": null,
                "agencia": null,
                "conta": null,
                "digito": null
            }
        

    ]);


    const [dataPag, setDataPag] = useState([

        {
                
            "token": null,
            "vl": null,
            "data_envio": null,
            "hora_envio": null,
            "data_pag": null,
            "hr_pag": null,
            "id": null
        }

    ]);


    const navigate = useNavigate();
    const { user } = useAuth();
    const { id } = useParams();


    async function chamaapi() {

        var data = JSON.stringify({
            "uuid": user.uuid,
            "metodo": 'SDFOR',
            "uuidClient": id
        });
        var axiosConfig = {
            headers: {
                'Content-Type': 'application/json',
            }
        };

        ilikeApi.defaults.headers.common['Authorization'] = `Bearer ${String(user.access_token)}`;

        try {
            const resposta = await ilikeApi.post(`/consultas/search`, data, axiosConfig);

            if(resposta.data.error === 0){
            setDataApi(resposta.data.res)
            setDataCliente(resposta.data.res.dados_cliente);
            setDataBanco(resposta.data.res.dados_banco);
            setDataPag(resposta.data.res.pag_rec);
            console.log(dataPag);
            }

            

        } catch (error) {

            console.log(error);
        }
    }



    useEffect(() => {
        chamaapi();
      
    }, []);



    


    return (
        <>
            <Menu />
            <S.body>
                <Cabecalho title={'Editar Fornecedor'} />
                <Divider />
                <S.section>
                    <Row>
                        <Col md={12}>
                            <br></br>
                            <br></br>
                            <S.cardTable>
                                <S.textTitleTble>Fornecedor: {dataCliente[0].nome}</S.textTitleTble>
                                <br></br>
                                <br></br>
                                <br></br>


                                <Col md={12}>
                                    <Tabs
                                        defaultActiveKey="home"
                                        id="uncontrolled-tab-example"
                                        className="mb-3"
                                        fill
                                    >
                                        <Tab eventKey="home" title={<S.textTabs >  <S.FaUse />  <br></br>Dados Cadastrais </S.textTabs>}>

                                        {dataApi && (<DadosCadastro data={dataCliente} /> )}
                                                
                                              
                                        
                                       

                                        </Tab>


                                        <Tab eventKey="profile" title={<S.textTabs >  <S.FSchool /> <br></br> Dados Bancários </S.textTabs>}>
                                       
                                        {/* {dataBanco && ( 
                                        <Dadosbancario data={dataBanco} />                                        
                                        )} */}

                                        {dataApi && (<Dadosbancario data={dataBanco} />  )}

                                       
                                       
                                       
                                       
                                       
                                        </Tab>


                                        <Tab eventKey="contact" title={<S.textTabs >  <S.FSearchDollar /> <br></br> Pagamentos Recebidos </S.textTabs>}>
                                        {/* {dataPag && ( 
                                        <DadosPagamentos data={dataPag} />
                                        )} */}

                                                {dataApi && (<DadosPagamentos data={dataPag} />  )}



                                        </Tab>
                                    </Tabs>
                                </Col>
                            </S.cardTable>
                        </Col>
                    </Row>
                </S.section>
                <Footer />
            </S.body>
        </>
    );
}


